import { dispatch } from './utils/customEventWrapper';
import * as authentication from './actions/authentication';
import { AmediaUserEvents } from './eventHandlers/eventMap';
import { getAidDomain, getCurrentUrl } from './utils/url';
import { runJanitor } from './janitor';
import { listenForStateRequests } from './eventHandlers/state';
import { listenForUserAttributeRequests } from './eventHandlers/userAttributes';
import { listenForStorageRequests } from './eventHandlers/storage';
import getGlobalThis from './utils/getGlobalThis';
import { listenForNavigationRequests } from './eventHandlers/navigation';
import { listenForFocus } from './eventHandlers/focus';
import { listenForDataRefreshRequest } from './eventHandlers/refreshData';
import { listenForNonUserAccessRequests } from './eventHandlers/access';

export { default as UserDataRequest } from './lib/UserDataRequest';
export {
  default as PaywallUnlockRequest,
  PaywallUnlockResponse,
} from './lib/PaywallUnlockRequest';
export { default as FetchTimeoutError } from './exceptions/FetchTimeoutError';
export { default as PaywallError } from './exceptions/PaywallError';
export { default as EmergencyModeError } from './exceptions/EmergencyModeError';
export { default as ValidationError } from './exceptions/ValidationError';
export {
  default as SiteAccessRequest,
  SiteAccessResponse,
} from './lib/SiteAccessRequest';

export type { State, UserAttributes, PrivacyPreferences } from './types';

/** @deprecated Should be deleted when removing saveStorage and updateStorage */
type Storage = Record<string, Record<string, string>>;

type LoginPageParams = {
  requestedUrl?: string;
  context?: string;
};

function isLoginPageParams(params: unknown): params is LoginPageParams {
  return (
    params &&
    Object.keys(params).some((p) => ['requestedUrl', 'context'].includes(p))
  );
}

/**
 * This will, if necessary, trigger a redirect to www.aid.no and return
 * to the requestedUrl if supplied or the current url before redirecting away if not.
 * @param requestedUrl
 */
export const goToLoginPage = (
  requestedUrlOrParams?: string | LoginPageParams
) => {
  if (isLoginPageParams(requestedUrlOrParams)) {
    authentication.login(
      requestedUrlOrParams.requestedUrl || getCurrentUrl(),
      requestedUrlOrParams.context || 'go_to_login_page'
    );
  } else {
    authentication.login(
      requestedUrlOrParams || getCurrentUrl(),
      'go_to_login_page'
    );
  }
};

export const getLoginUrl = (
  requestedUrlOrParams?: string | LoginPageParams
) => {
  if (isLoginPageParams(requestedUrlOrParams)) {
    return authentication.getLoginUrl(
      requestedUrlOrParams.requestedUrl || getCurrentUrl(),
      requestedUrlOrParams.context || 'get_login_url'
    );
  }
  return authentication.getLoginUrl(
    requestedUrlOrParams || getCurrentUrl(),
    'get_login_url'
  );
};

export const logout = () => authentication.logout();

/**
 * @deprecated Use Namespace.save() instead
 * No longer used by any active app. Can be removed
 */
export const updateStorage = (storage: Storage) => {
  dispatch(
    new CustomEvent(AmediaUserEvents.UPDATE_STORAGE, { detail: storage })
  );
};

/**
 * @deprecated Use Namespace.save() instead
 * No longer used by any active app. Can be removed
 */
export const saveStorage = (storage: Storage) => {
  dispatch(new CustomEvent(AmediaUserEvents.SAVE_STORAGE, { detail: storage }));
};

export const requestDataRefresh = () => {
  dispatch(new CustomEvent(AmediaUserEvents.REQUEST_DATA_REFRESH));
};

const baseUrl = `https://${getAidDomain()}/aid`;
export const aidUrls = {
  mainPage: new URL(baseUrl),
  privacyPolicy: new URL(`${baseUrl}/personvern`),
  termsOfService: new URL(`${baseUrl}/brukervilkar`),
  family: new URL(`${baseUrl}/familie`),
  profile: new URL(`${baseUrl}/profil`),
  sites: new URL(`${baseUrl}/nettsteder`),
};

if (!getGlobalThis().amediaUserTestMode) {
  listenForNavigationRequests();
  listenForStateRequests();
  listenForUserAttributeRequests();
  listenForStorageRequests();
  listenForFocus();
  listenForDataRefreshRequest();
  listenForNonUserAccessRequests();
  runJanitor();
}
