{
  "name": "@amedia/user",
  "version": "0.14.137",
  "description": "Client lib for working with aID user and associated data",
  "repository": "https://github.com/amedia/amedia-user-js",
  "author": "Amedia Utvikling (ID og data)",
  "type": "module",
  "module": "dist/user.js",
  "exports": {
    ".": {
      "import": "./dist/user.js",
      "default": "./dist/user.js"
    }
  },
  "files": [
    "dist/*"
  ],
  "typings": "dist/user.d.ts",
  "eik": {
    "server": "https://assets.acdn.no",
    "files": {
      "user.js": "./dist/user.js",
      "user.js.map": "./dist/user.js.map",
      "user.d.ts": "./dist/user.d.ts"
    }
  },
  "scripts": {
    "changeset": "changeset",
    "release": "changeset publish",
    "test": "jest --coverage",
    "format": "kragl format",
    "lint": "kragl lint",
    "lint-fix": "kragl lint --fix",
    "clean-dist": "rm -rf ./dist",
    "build": "npm install && node build.js & npm run build:types",
    "build:types": "npx rollup -c ./types.js",
    "dev": "concurrently --kill-others \"node build.js --watch\" \"node server.js\"",
    "typings": "tsc --noEmit --resolveJsonModule",
    "checkVersion": "node --experimental-json-modules checkVersion.js",
    "stub-npmjs-package": "find ./dist -type f ! -name '*.d.ts' -delete && cp -v stubs/* dist/.",
    "eik:package": "run-p typings lint && npm run test && npm run build && npx @eik/cli publish",
    "prepublishOnly": "run-p clean-dist typings lint && npm run test && npm run build && npm run stub-npmjs-package",
    "postpublish": "npm run build && npx @eik/cli publish"
  },
  "dependencies": {
    "@amedia/browserid": "1.0.17",
    "@amedia/frontend-rpc": "0.0.12",
    "runtypes": "6.7.0",
    "ts-deepmerge": "7.0.2"
  },
  "devDependencies": {
    "@amedia/eslint-config": "4.0.4",
    "@amedia/kragl": "5.0.5",
    "@amedia/prettier-config": "2.0.0",
    "@changesets/changelog-github": "0.5.1",
    "@changesets/cli": "2.28.1",
    "@eik/esbuild-plugin": "1.1.53",
    "@types/jest": "29.5.14",
    "@types/node": "22.14.0",
    "concurrently": "9.1.2",
    "cors": "2.8.5",
    "esbuild": "0.25.2",
    "express": "4.21.2",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-extended": "4.0.2",
    "jest-fetch-mock": "3.0.3",
    "mockdate": "3.0.5",
    "npm-run-all": "4.1.5",
    "rollup": "4.39.0",
    "rollup-plugin-dts": "6.2.1",
    "ts-jest": "29.3.1",
    "tslib": "2.8.1",
    "typescript": "5.8.2"
  }
}
